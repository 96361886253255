import React, { useState, useEffect } from 'react'
import { PropTypes } from 'prop-types'

import { useMounted, SubscriptionStatusLabel } from '../../../../utils'
import { useTranslation, useBackendApi } from '../../../../utils/providers'
import {
  AlertBox,
  AlertBoxTypes,
  Button,
  QtySelect,
  Link,
} from '../../../index'

function SubscriptionItem({
  qty: initQty,
  productData,
  subscriptionData,
  setError,
  hidePopup,
}) {
  const [qty, setQty] = useState(initQty)
  const { t } = useTranslation()
  const backendApi = useBackendApi()
  const [isLoading, setIsLoading] = useState(false)

  const isMounted = useMounted()

  const { id: productId, sku, availability } = productData
  const {
    id: subscriptionId,
    label,
    isEditable,
    status,
    deliveryInterval,
    price,
    items,
  } = subscriptionData

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const onAddProduct = async () => {
    setError(null)
    setIsLoading(true)

    window.dispatchEvent(
      new CustomEvent('addtosubscription:request', {
        detail: { productData, subscriptionId },
      })
    )

    try {
      const { status, message, finalPrice } =
        await backendApi.subscriptionAddItem({
          subscriptionId,
          sku,
          qty,
        })

      if (isMounted.current) {
        if (status !== 'ok') {
          setError(message)
        } else {
          hidePopup()
        }
        window.dispatchEvent(
          new CustomEvent('addtosubscription:response', {
            detail: {
              status,
              message,
              productData,
              subscriptionId,
              finalPrice,
            },
          })
        )
      }
    } catch (error) {
      if (isMounted.current) {
        const errorMessage = error?.message || t('GENERAL_ERROR')
        setError(errorMessage)
        window.dispatchEvent(
          new CustomEvent('addtosubscription:response', {
            detail: {
              status: 'error',
              message: errorMessage,
              productData,
              subscriptionId,
            },
          })
        )
      }
    } finally {
      if (isMounted.current) {
        setIsLoading(false)
      }
    }
  }

  const isProductExist = items.some((item) => item.sku === sku)

  const nextDeliveryStatus = isEditable
    ? 'ABO_ADJUSTMENT_POSSIBLE'
    : 'ABO_IS_BEING_PACKED'

  return (
    <li className="pdp__subscription-items">
      {label && (
        <p className="mb-2">{`${t('PDP_ADD_TO_SUBSCRIPTION_POPUP_LABEL_NAME')(
          label
        )}`}</p>
      )}

      <dl className="mb-3">
        <div className="d-flex">
          <dt className="w-50 font-weight-normal">
            {t('PDP_ADD_TO_SUBSCRIPTION_POPUP_LABEL_STATUS')}
          </dt>
          <dd className="w-50 mb-0">{t(SubscriptionStatusLabel[status])}</dd>
        </div>
        <div className="d-flex">
          <dt className="w-50 font-weight-normal">
            {t('PDP_ADD_TO_SUBSCRIPTION_POPUP_LABEL_INTERVAL')}
          </dt>
          <dd className="w-50 mb-0">
            {t('WEEKS')(parseInt(deliveryInterval))}
          </dd>
        </div>
        <div className="d-flex">
          <dt className="w-50 font-weight-normal">
            {t('PDP_ADD_TO_SUBSCRIPTION_POPUP_LABEL_SUM')}
          </dt>
          <dd className="w-50 mb-0">{price}</dd>
        </div>
        <div className="d-flex">
          <dt className="w-50 font-weight-normal">
            {t('PDP_ADD_TO_SUBSCRIPTION_POPUP_LABEL_ADJUSTMENT')}
          </dt>
          <dd className="w-50 mb-0">{t(nextDeliveryStatus)}</dd>
        </div>
      </dl>

      {!isEditable || isProductExist ? (
        <AlertBox type={AlertBoxTypes.INFO}>
          {isProductExist
            ? t('ABO_PDP_PRODUCT_EXIST')({
                Link,
                props: {
                  href: `/cartUpdate/abodelivery/view#id_${subscriptionId}`,
                  className: 'text-underline',
                },
              })
            : t('ABO_PDP_NOT_EDITABLE')}
        </AlertBox>
      ) : (
        <>
          <QtySelect
            className="mb-3"
            isDisabled={!availability}
            min={1}
            max={10}
            qty={qty}
            changeQty={setQty}
            type="inputBox"
            productId={productId}
          />

          <Button
            variant="primary"
            className="w-100"
            onClick={onAddProduct}
            isLoading={isLoading}
          >
            {t('ABO_POPUP_ADD_PRODUCT_CTA')}
          </Button>
        </>
      )}
    </li>
  )
}

SubscriptionItem.propTypes = {
  qty: PropTypes.number,
  productData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    sku: PropTypes.string.isRequired,
    availability: PropTypes.bool.isRequired,
  }).isRequired,
  subscriptionData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    isEditable: PropTypes.bool.isRequired,
    status: PropTypes.string.isRequired,
    deliveryInterval: PropTypes.number.isRequired,
    price: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        sku: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  setError: PropTypes.func.isRequired,
  hidePopup: PropTypes.func.isRequired,
}

export default SubscriptionItem
