const SubscriptionStatus = {
  ACTIVE: 'active',
  PAUSED: 'paused',
  CANCELED: 'canceled',
}

export const SubscriptionStatusLabel = {
  [SubscriptionStatus.ACTIVE]: 'ABO_STATUS_ACTIVE',
  [SubscriptionStatus.PAUSED]: 'ABO_STATUS_PAUSED',
  [SubscriptionStatus.CANCELED]: 'ABO_STATUS_CANCELED',
}

export default SubscriptionStatus
