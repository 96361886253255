import React from 'react'
import { PropTypes } from 'prop-types'

import { useFeatureFlags } from '../../../utils/providers'
import ProductRating from '../ProductRating'
import Schema from './Schema'

const AboutProduct = ({ ratings, productData }) => {
  const { isFeatureEnabled } = useFeatureFlags()
  const rating = ratings ? ratings[productData.id] : null

  return (
    <>
      <div className="product-detail-page__header d-md-flex align-items-md-center mb-3">
        <a
          className="font-size-xl font-weight-bold d-inline-block mr-3"
          href={productData.manufacturer_url}
        >
          {productData.manufacturer_name} {productData.produkt_linie}
        </a>
        {rating && isFeatureEnabled('product.ratings') && (
          <ProductRating
            productData={productData}
            ratingSummary={rating}
            ratingUrl={false}
          />
        )}
      </div>
      <p className="mb-1 font-size-m">{productData.name}</p>
      <Schema productRatings={rating} productData={productData} />
    </>
  )
}

AboutProduct.propTypes = {
  productData: PropTypes.shape({
    id: PropTypes.string,
    manufacturer_name: PropTypes.string,
    name: PropTypes.string,
    manufacturer_url: PropTypes.string,
    produkt_linie: PropTypes.string,
  }),
  ratings: PropTypes.object,
}

export default AboutProduct
