import React from 'react'
import { TranslationComponent } from '../../../utils/core/TranslationProvider'
import ClassNames from 'classnames'
import ProductLink from '../Link/ProductLink'

export default function VariantsRenderer(props) {
  const {
    variantLabel,
    variantLabelContent,
    variants,
    render = '',
    productData,
  } = props
  return (
    <>
      <div className="mb-2">
        <TranslationComponent text={variantLabel} />
        <strong>{variantLabelContent}</strong>
      </div>
      <div className="product-variants mb-3">
        {variants.map((product) => {
          if (!product.active) {
            return null
          }
          const url = product.url
          let name = product.name
          if (render === 'config_a') {
            name = product.config_variable_a
          }
          const selectedVariantClassName = ClassNames(
            'product-variants__item text-truncate',
            {
              ['product-variants__item--selected']:
                product.id == productData.id,
              ['product-variants__item--disabled']: !product.availability,
            }
          )

          return (
            <ProductLink
              href={url}
              className={selectedVariantClassName}
              key={product.id}
              data-sku={product.sku}
            >
              {name}
            </ProductLink>
          )
        })}
      </div>
    </>
  )
}
