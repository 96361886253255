import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Image } from '../..'

function NotificationBanner({
  colorScheme,
  icon,
  title,
  message,
  isFullWidth,
  fontWeightTitle,
  alignment,
}) {
  if (!title && !message) {
    return null
  }

  const wrapperClasses = classNames('notification-banner', {
    container: !isFullWidth,
    [`notification-banner--${colorScheme}`]: [
      'primary',
      'primary-lighten-20',
    ].includes(colorScheme),
  })
  const contentClasses = classNames('notification-banner__content', {
    container: isFullWidth,
    [`text-${alignment}`]: ['left', 'center', 'right'].includes(alignment),
  })
  const titleClasses = classNames('notification-banner__title', {
    [`font-weight-${fontWeightTitle}`]: [
      'normal',
      'semi-bold',
      'bold',
    ].includes(fontWeightTitle),
  })

  return (
    <section className={wrapperClasses}>
      <div className={contentClasses}>
        {icon && (
          <Image className="notification-banner__icon mr-3" srcDesktop={icon} />
        )}
        <div className="notification-banner__text-container">
          {title && <strong className={titleClasses}>{title}</strong>}
          {message && <p className="notification-banner__message">{message}</p>}
        </div>
      </div>
    </section>
  )
}

NotificationBanner.propTypes = {
  colorScheme: PropTypes.oneOf(['primary', 'primary-lighten-20']),
  icon: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  isFullWidth: PropTypes.bool,
  fontWeightTitle: PropTypes.oneOf(['normal', 'semi-bold', 'bold']),
  alignment: PropTypes.oneOf(['left', 'center', 'right']),
}

NotificationBanner.defaultProps = {
  colorScheme: 'primary',
  icon: null,
  title: null,
  message: null,
  isFullWidth: true,
  fontWeightTitle: 'bold',
  alignment: 'left',
}

export default NotificationBanner
export { default as notificationBannerVariants } from './variants'
