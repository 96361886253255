import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import badgePillVariants from './variants'

function BadgePill({ className, children }) {
  const cls = classNames(
    className,
    'badge badge-pill bg-primary-lighten-20 text-uppercase font-size-xs'
  )
  return <span className={cls}>{children}</span>
}

BadgePill.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
}

export default BadgePill
export { badgePillVariants }
