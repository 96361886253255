import React, { useContext, useState } from 'react'
import { PropTypes } from 'prop-types'
import classNames from 'classnames'

import {
  PricingContext,
  useFeatureFlags,
  useSnippetContent,
} from '../../../../utils/providers'
import { trackSubscriptionToggle } from '../../../../utils/Alphapet/tracking'

import { BadgePill, Icon, InlineButton } from '../../../index'
import { ToggleSwitch } from '../../Form'
import PopupAction from './PopupAction'
import InfoItems from './InfoItems'

const calculatePercentage = (regularPrice, discountPrice) => {
  const discountValue = discountPrice - regularPrice
  const percentage = Math.round((discountValue / regularPrice) * 100)
  return `${percentage}%`
}

function SubscriptionBox({ productData = {}, qty = 1, ratings }) {
  const [isSubscriptionActive, setSubscriptionActive] = useState(false)
  const [isPopupActive, setPopupActive] = useState(false)
  const context = useContext(PricingContext)
  const { isFeatureEnabled } = useFeatureFlags()
  const {
    subscriptionBox: {
      infoItems = [],
      percentageLabel,
      subscriptionLabel,
    } = {},
  } = useSnippetContent('pdp_config') || {}

  const { getAboPrice, setAbo } = context?.pricingContext || {}
  const {
    priceinformation: [prices] = [],
    availability: isAvailable,
    sku,
  } = productData

  const toggleSubscription = () => {
    const isActive = !isSubscriptionActive
    setSubscriptionActive(isActive)
    setAbo(isActive)

    trackSubscriptionToggle(
      sku,
      getAboPrice(productData),
      isActive ? 'select' : 'deselect'
    )
  }

  const hidePopup = () => {
    setPopupActive(false)
    setAbo(isSubscriptionActive)
  }

  const addToSubscription = () => {
    setPopupActive(true)
    setAbo(true)
  }

  if (!prices || !isAvailable) {
    return null
  }

  const { regular: regularPrice, special: specialPrice } = prices
  const aboPrice = getAboPrice(productData)

  const aboDiscount = calculatePercentage(
    regularPrice || specialPrice,
    aboPrice
  )

  const subscriptionToggleWrapperClasses = classNames(
    'd-flex justify-content-between align-items-center',
    {
      'border-bottom-1 pb-2 mb-2':
        infoItems.length > 0 || isFeatureEnabled('abo.editable'),
    }
  )
  const badgePillClasses = classNames('ml-2 border border-primary', {
    'bg-white': !isSubscriptionActive,
  })

  return (
    <form className="pdp__subscription p-3 p-md-0 mt-3 ">
      {percentageLabel && (
        <div className={subscriptionToggleWrapperClasses}>
          <Icon
            symbol="cf-box-refresh-o"
            className="font-size-m mr-2 text-gray-5"
          />

          <span className="flex-grow font-weight-semi-bold font-size-m">
            {percentageLabel}
            <BadgePill className={badgePillClasses}>{aboDiscount}</BadgePill>
          </span>

          <ToggleSwitch
            onChange={toggleSubscription}
            data={{ name: 'subscriptionActive', value: isSubscriptionActive }}
            colorScheme="alternate"
          />
        </div>
      )}

      {infoItems?.length > 0 && (
        <InfoItems
          infoItems={infoItems}
          isSubscriptionActive={isSubscriptionActive}
        />
      )}

      {isFeatureEnabled('abo.editable') && (
        <div className="d-flex align-items-center justify-content-between">
          {subscriptionLabel && (
            <p className="text-underline mt-2 mb-0">
              <InlineButton
                className="text-underline font-weight-semi-bold"
                onClick={addToSubscription}
              >
                {subscriptionLabel}
              </InlineButton>
            </p>
          )}
        </div>
      )}

      {isPopupActive && (
        <PopupAction
          productData={productData}
          qty={qty}
          hidePopup={hidePopup}
          ratings={ratings}
        />
      )}
    </form>
  )
}

SubscriptionBox.propTypes = {
  productData: PropTypes.shape({
    priceinformation: PropTypes.arrayOf(PropTypes.object),
    availability: PropTypes.bool,
    sku: PropTypes.string,
  }),
  qty: PropTypes.number,
  ratings: PropTypes.object,
}

export default SubscriptionBox
