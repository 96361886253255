import React, { useEffect, useState } from 'react'

import { useBackendApi, useCustomer } from '../../../../utils/providers'
import { CenterPopup, PopupOverlay } from '../../../index'
import { dispatchLoginPopupShowEvent } from '../../LoginPopup'
import { LoadingIcon } from '../../Icons'
import { useMounted } from '../../../../utils'

import AddToSubscriptionView from './AddToSubscriptionView'
import AddToCartView from './AddToCartView'

function PopupAction({ productData, qty, hidePopup, ratings }) {
  const { isLoggedIn, isAvailable } = useCustomer()
  const backendApi = useBackendApi()
  const [isLoading, setLoading] = useState(false)
  const [hasLoadingError, setLoadingError] = useState(false)
  const [subscriptionList, setSubscriptionList] = useState([])
  const isMounted = useMounted()

  useEffect(() => {
    if (isLoggedIn) {
      setLoading(true)
      setLoadingError(false)

      backendApi
        .getActiveSubscriptionDetails()
        .then(({ subscriptions: items }) => {
          if (isMounted.current) {
            setSubscriptionList(items)
          }
        })
        .catch((error) => {
          console.error(error)
          if (isMounted.current) {
            setLoadingError(true)
          }
        })
        .finally(() => {
          if (isMounted.current) {
            setLoading(false)
          }
        })
    } else if (isAvailable) {
      setSubscriptionList([])
      dispatchLoginPopupShowEvent({ onAbort: hidePopup })
    }
  }, [isLoggedIn, isAvailable])

  return (
    <>
      {isLoggedIn && (
        <CenterPopup
          closeAction={hidePopup}
          className="pdp__subscription-popup p-0"
        >
          <div className="pdp__subscription-popup-content">
            {isLoading ? (
              <PopupOverlay>
                <LoadingIcon />
              </PopupOverlay>
            ) : subscriptionList.length > 0 ? (
              <AddToSubscriptionView
                qty={qty}
                productData={productData}
                subscriptionList={subscriptionList}
                hidePopup={hidePopup}
                subscriptionCount={subscriptionList.length}
                hasLoadingError={hasLoadingError}
              />
            ) : (
              <AddToCartView
                qty={qty}
                productData={productData}
                ratings={ratings}
                hidePopup={hidePopup}
                hasLoadingError={hasLoadingError}
              />
            )}
          </div>
        </CenterPopup>
      )}
    </>
  )
}

export default PopupAction
