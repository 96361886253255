import React from 'react'

import ProductPrices from '../PriceInfo/ProductPrices'
import ProductPriceHint from '../PriceInfo/ProductPriceHint'
import ProductBundleOptions from '../ProductBundleOptions'
import BundleProvider from '../ProductBundleOptions/BundleProvider'
import { useFeatureFlags } from '../../../utils'

import BuyBox from './BuyBox'
import { BundlePdpVariants } from './PdpVariants'
import RelatedProducts from './RelatedProducts'
import DeliveryPromise from './DeliveryPromise'

export default function BundleProductRender({ productData, ratings }) {
  const { isFeatureEnabled } = useFeatureFlags()

  return (
    <BundleProvider productData={productData}>
      <div className="col-12 col-md-6">
        <ProductPrices
          productData={productData}
          classSpecialPrice="font-size-xl font-weight-semi-bold"
          classRegularPrice="d-inline-block ml-2 font-size-l"
          classUnitPrice="font-size-xxs d-block"
        />
        <ProductPriceHint className="font-size-xs" />
        <BundlePdpVariants productData={productData} />
        <ProductBundleOptions productData={productData} />
        {isFeatureEnabled('relatedProducts') && !productData.aktionen_apv && (
          <RelatedProducts productData={productData} />
        )}
      </div>
      <div className="col-12 col-md-6">
        <BuyBox
          productData={productData}
          disableVariants={true}
          ratings={ratings}
        />
        <DeliveryPromise availability={productData.availability} />
      </div>
    </BundleProvider>
  )
}
