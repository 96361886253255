import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'

import { QtySelect, ProductActionButton } from '../..'
import {
  isAboProduct,
  setCartWidgetOffset,
  useStickyScrollRefs,
} from '../../../utils'
import { useFeatureFlags, useMediaResize } from '../../../utils/providers'
import SubscriptionBox from './SubscriptionBox'

function BuyBox({ productData, disableVariants, ratings }) {
  const [qty, setQty] = useState(1)
  const { isFeatureEnabled } = useFeatureFlags()
  const { breakpoints } = useMediaResize()
  const { isSticky, innerRef, outerRef } = useStickyScrollRefs()

  useEffect(() => {
    const offset = isSticky ? innerRef?.current?.clientHeight : 0
    setCartWidgetOffset(offset)
  }, [isSticky, breakpoints.downMD, innerRef?.current])

  const actionBtn = (
    <ProductActionButton
      productData={productData}
      qty={qty}
      disableVariants={disableVariants}
      trackingData={{ origin: 'pdp' }}
    />
  )

  return (
    <div className="product-detail-page__action-box">
      <QtySelect
        isDisabled={!productData.availability}
        min={1}
        max={10}
        qty={qty}
        changeQty={setQty}
        type="inputBox"
      />
      {breakpoints.downMD ? (
        <div ref={outerRef}>
          <div ref={innerRef} className={isSticky ? 'fixed-bottom' : null}>
            {actionBtn}
          </div>
        </div>
      ) : (
        actionBtn
      )}
      {isFeatureEnabled('abo') && isAboProduct(productData) && (
        <SubscriptionBox
          qty={qty}
          productData={productData}
          ratings={ratings}
        />
      )}
    </div>
  )
}

BuyBox.propTypes = {
  productData: PropTypes.shape({
    availability: PropTypes.bool,
  }),
  disableVariants: PropTypes.bool,
  ratings: PropTypes.object,
}

export default BuyBox
