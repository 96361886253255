import React, { useState } from 'react'
import { PropTypes } from 'prop-types'

import { useTranslation } from '../../../../utils/providers'
import { AlertBox } from '../../../'
import SubscriptionItem from './SubscriptionItem'

function AddToSubscriptionView({
  qty,
  productData,
  subscriptionList,
  hidePopup,
  subscriptionCount,
  hasLoadingError,
}) {
  const [hasError, setError] = useState(
    hasLoadingError ? t('REQUEST_ERROR') : null
  )
  const { t } = useTranslation()

  return (
    <>
      <div className="pdp__subscription-headline">
        <h1 className="h2">{t('ABO_PDP_ADD_PRODUCT_TITLE')}</h1>
        {hasError && <AlertBox>{hasError}</AlertBox>}
        <strong className="font-size-l mt-3">
          {t('ABO_PDP_SUBSCRIPTIONS')(subscriptionCount)}
        </strong>
      </div>

      {subscriptionList?.length > 0 ? (
        <ul>
          {subscriptionList.map((subscriptionData) => (
            <SubscriptionItem
              key={subscriptionData.id}
              qty={qty}
              productData={productData}
              subscriptionData={subscriptionData}
              setError={setError}
              hidePopup={hidePopup}
            />
          ))}
        </ul>
      ) : (
        <p>{t('ABO_PDP_NO_SUBSCRIPTIONS')}</p>
      )}
    </>
  )
}

AddToSubscriptionView.propTypes = {
  qty: PropTypes.number.isRequired,
  productData: PropTypes.object.isRequired,
  subscriptionList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  hidePopup: PropTypes.func.isRequired,
  subscriptionCount: PropTypes.number.isRequired,
  hasLoadingError: PropTypes.bool,
}

export default AddToSubscriptionView
