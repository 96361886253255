import React from 'react'
import { PropTypes } from 'prop-types'
import classNames from 'classnames'

import { Icon } from '../../../index'

const InfoItems = ({ infoItems, isSubscriptionActive }) => {
  const infoItemClasses = classNames('pdp__subscription-info-items', {
    'pdp__subscription-info-items--active': isSubscriptionActive,
  })

  return (
    <ul className={infoItemClasses}>
      {infoItems.map(({ icon, label }, index) => (
        <li key={index} className="d-flex align-items-center">
          <Icon
            symbol={isSubscriptionActive ? 'cf-check-italic' : icon}
            className="font-size-m mr-2"
          />
          <span>{label}</span>
        </li>
      ))}
    </ul>
  )
}

InfoItems.propTypes = {
  infoItems: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  isSubscriptionActive: PropTypes.bool,
}

export default InfoItems
