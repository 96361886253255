import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import ErrorMessage from '../ErrorMessage'

export default function ToggleSwitch({
  className = null,
  onChange,
  data: { value, error, name },
  labelUnchecked,
  labelChecked,
  checked = false,
  hidden = false,
  colorScheme = 'default',
  htmlId,
  disabled,
  ...rest
}) {
  const [isChecked, setChecked] = useState(value || checked || false)

  const handleChange = (evt) => {
    setChecked(evt.target?.checked)
    if (typeof onChange === 'function') {
      onChange(evt)
    }
  }

  const groupClasses = classNames('form-toggle', className, {
    disabled: !!disabled,
    'form-toggle--alternate': colorScheme === 'alternate',
  })

  const id = htmlId || name

  useEffect(() => {
    setChecked(value || checked)
  }, [value, checked])

  return (
    <div className={groupClasses} hidden={hidden || null}>
      <div className="form-toggle__widget">
        <input
          type="checkbox"
          id={id}
          name={name}
          checked={isChecked}
          onChange={handleChange}
          disabled={disabled}
          className="sr-only"
          {...rest}
        />
        <label htmlFor={id} />
        <span className="label-unchecked">{labelUnchecked}</span>
        <span className="label-checked">{labelChecked}</span>
      </div>
      <ErrorMessage message={error} />
    </div>
  )
}

/**
 * Additional props not covered here, will be passed to the '<input>' element.
 */
ToggleSwitch.propTypes = {
  /**
   * A CSS class added to the container element (optional).
   */
  className: PropTypes.string,

  /**
   * The data object which identifies the input by name, holds the initial
   * value, and an error message (can be a component).
   *
   * See also '../Form/index.js'
   */
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.bool,
    error: PropTypes.any,
  }).isRequired,

  /**
   * Disables the input field (optional).
   */
  disabled: PropTypes.bool,

  /**
   * An unchecked label text to be rendered (optional, can be a component).
   */
  labelUnchecked: PropTypes.any,

  /**
   * A checked label text to be rendered (optional, can be a component).
   */
  labelChecked: PropTypes.any,

  /**
   * Callback that is called by 'Form.onChange()' when the value of the input
   * element changed. See 'data' prop, and '../Form/index.js'.
   *
   * @param {string} name - The 'name' attribute of the input element
   * @param {boolean} value - The selected/entered date
   * @param {any} error - An error message in case validation fails
   */
  onChange: PropTypes.func.isRequired,

  /**
   * Set the initial state to be checked (optional).
   */
  checked: PropTypes.bool,

  /**
   * Set the 'hidden' attribute to the component wrapper (optional).
   */
  hidden: PropTypes.bool,

  /**
   * Override the 'id' attribute of the form element (optional, defaults to
   * 'data.name').
   */
  htmlId: PropTypes.string,

  /**
   * Determines the color scheme of the toggle switch (optional, defaults to 'default').
   */
  colorScheme: PropTypes.oneOf(['default', 'alternate']),
}
